import Button from '@components/AcoButtons/Button';
import { Box, CircularProgress, Divider, Fade, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSWRInfinite } from 'swr';
import { Fetcher } from 'swr/dist/types';

interface Props<T, I = T[]> {
  skeletonNode?: ReactNode;
  emptyNode?: ReactNode;
  noMoreNode?: ReactNode;
  loaderNode?: ReactNode;
  errorNode?: ReactNode;
  className?: string;
  buildKey: (index: number) => string;
  fetcher: Fetcher<T[]>;
  renderItem: (item: I, i: number) => ReactNode;
  transformItems?: (items: T[]) => I[];
  refresh?: boolean;
  onRefreshComplete?: () => void;
  isHome?: boolean;
  isModal?: boolean;
}

const defaultStyles = makeStyles({
  root: {
    overflow: 'visible !important',
  },
  spacing: {
    '& > *': {
      margin: '10px',
    },
  },
});

export function InfiniteScrolling<T>(props: Props<T>) {
  const classes = defaultStyles();
  let index = 0;

  const [modalHeight, setModalHeight] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      setModalHeight(modalRef.current.clientHeight);
    }
  }, []);

  const handleScroll = () => {
    if (modalRef.current) {
      const scrollTop = modalRef.current.scrollTop;
      const scrollHeight = modalRef.current.scrollHeight;
      const clientHeight = modalRef.current.clientHeight;

      // Determine if the scroll position is near the bottom of the modal
      const nearBottom = scrollTop + clientHeight >= scrollHeight - 20; // Adjust as needed

      if (nearBottom) {
        // Load more data when near the bottom of the modal
        setSize(size + 1);
      }
    }
  };

  const transformItems = props.transformItems || ((items) => items);
  const { refresh, onRefreshComplete } = props;

  const { data, error, size, setSize, mutate } = useSWRInfinite((i) => {
    index = i;

    return props.buildKey(i);
  }, props.fetcher);

  useEffect(() => {
    if (refresh) {
      mutate().then(() => {
        if (onRefreshComplete) {
          onRefreshComplete();
        }
      });
    }
  }, [refresh, mutate, onRefreshComplete]);

  const items = data ? [].concat(...data) : [];
  const isLoadingInitialData = !data && !error;

  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');

  const isEmpty = data?.[0]?.length === 0;

  const hasMore = !isEmpty && !(data?.[data.length - 1]?.length === 0);

  const skeletonNode = props.skeletonNode || <Skeleton variant='rectangular' height={200} />;

  const emptyState = (lineOne: string, lineTwo: string) => (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' marginTop='10px'>
      <Divider orientation='horizontal' style={{ width: '100%' }} />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        padding='30px'
        className={classes.spacing}>
        <Typography variant='h5' color='secondary' align='center'>
          {lineOne}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary' align='center'>
          {lineTwo}
        </Typography>
        {props.isHome && <Button color='primary' href='/' variant='contained' size='large'>
          Volver al inicio
        </Button>
        }
      </Box>
    </Box>
  );

  const emptyNode =
    props.emptyNode ||
    emptyState('Por el momento no tenemos nada para mostrarte', 'Volvé al inicio para seguir viendo más');

  const noMoreNode =
    props.noMoreNode || emptyState('¡Llegaste hasta el final!', 'Volvé al inicio para seguir viendo más');

  const loaderNode = props.loaderNode || (
    <Grid container item xs={12} alignItems='center' justifyContent='center'>
      <Fade in={isLoadingMore && !isLoadingInitialData} unmountOnExit>
        <CircularProgress
          style={{
            marginTop: '20px',
            color: '#828282',
          }}
        />
      </Fade>
    </Grid>
  );

  const errorNode = props.errorNode || (
    <Grid container item xs={12} alignItems='center' justifyContent='center'>
      <Typography variant='h5' color='error'>
        Error, intenta nuevamente mas tarde.
      </Typography>
    </Grid>
  );

  const className = props.className || classes.root;

  const commonContent = (
    <>
      {isLoadingInitialData && skeletonNode}
      <InfiniteScroll
        dataLength={data ? data.length : 0}
        next={() => setSize(size + 1)}
        hasMore={hasMore}
        endMessage={isEmpty ? emptyNode : noMoreNode}
        className={className}
        loader={loaderNode}
      >
        <Grid container spacing={2}>
          {transformItems(items).map((item, i) => props.renderItem(item, index * size + i))}
        </Grid>
      </InfiniteScroll>
      {error && errorNode}
    </>
  );

  return (
    props.isModal ? (
      <div ref={modalRef} onScroll={handleScroll} style={{ maxHeight: '55vh', overflowY: 'auto' }}>
        {commonContent}
      </div>
    ) : (
      <div>
        {commonContent}
      </div>
    )
  );
}
