import { DocumentCardProps, NewsletterCardProps, PostCardProps, TicketCardProps } from '@components/cards/card';

/**
 * @deprecated
 */
export enum ObjectType {
  TICKET = 'Ticket',
  POST = 'Post',
  NEWSLETTER = 'Newsletter',
  DOCUMENT = 'Document',
  USER = 'User',
  BUSINESS = 'Business'
}

export enum EventsType {
  EVENTOS_TECNICOS = 'Eventos_Tecnicos',
  EVENTO_DIFUSION = 'Eventos_Difusion',
}

export interface FileTempUploadResponse {
  success: boolean;
  failure?: string;
  files: {
    content_length: number;
    content_type: string;
    filename: string;
    tmpfilename: string;
    tmpfileurl: string;
  }[];
}

export declare type ObjectProps = TicketCardProps | PostCardProps | NewsletterCardProps | DocumentCardProps;

export interface AcoObject {
  type: ObjectType;
  props: ObjectProps;
}

export interface ItemsOptions {
  op: string;
  page: number;
  size: number;
}

export interface ObjectResponse {
  objects: AcoObject[];
}

export enum Activity {
  TICKET = 'Consulta',
  POST = 'Publicacion',
  NEWSLETTER = 'Boletin',
  DOCUMENT = 'Documento',
  DOCUMENT_SPONSORED = 'DocumentoSponsoreado',
  ONLINE_EVENT = 'EventoOnline',
  BUSINESS = 'Empresa',
  USER = 'Usuario',
  COMMENT = 'Comentario',
  TOOL = 'Herramienta',
  BANNER = 'Banner',
}

export interface BaseObject {
  actividad: Activity;

  [key: string]: unknown;
}

/**
 * START POST
 */

export interface Post extends BaseObject, SocialProps {
  fecha_alta: string;
  empresa?: Business;
  privacidad?: {
    success?: string;
    error?: string;
    privacidad: string;
  };
  adjuntos: PostAttachment[];
  reference: string;
  url: string;
  id: number;
  imagenes: PostImageAttachment[];
  ref_publicador_id: number;
  contenido_abstracto: string;
  min_content?: string;
  frames?: string;
  usuario: User;
  localidad?: string;
  ref_publicador_pkg: string;
  fecha: string;
  t_compartir: number;
  archivos_adjuntos: PostAttachment[];
  ubicacion?: Location;
  publicacion_id: number;
  objetos: BaseObject[];
  tpublicacion: {
    tpublicacion_id: number;
    descripcion: string;
    atributo: number;
  };
  contenido: string;
}

export interface PostAttachment {
  publcacion_id: number;
  orden: number;
  filename: string;
  content_type: string;
  content_length: number;
  isImage: number;
  url: string;
}

export interface PostImageAttachment extends PostAttachment {
  size: { w: string; h: string };
}

export interface SocialProps {
  comentarios: number;
  cantidad_favoritos: number;
  cant_compartida: number;
  favorito: number;
  ultimos_comentarios: CommentMin[];
  favoritos_destacados: User[];
}

/**
 * START NEWSLETTER
 */

export interface Newsletter extends BaseObject, SocialProps {
  usuarios_expertos: number;
  fecha_alta: string;
  cuerpo: string;
  reference: string;
  empresas: number;
  boletines: number;
  url: string;
  id: number;
  fecha_envio: string;
  numero: number;
  asunto: string;
  cuerpo_resumen: string;
  documentos: number;
  ano: number;
  expertos: number;
  fecha_relativa_corta: string;
  consultas: number;
  objetos?: {
    usuarios_expertos: User[];
    expertos: User[];
    empresas: Business[];
    boletines: Newsletter[];
    documentos: Document[];
    consultas: Ticket[];
  };
}

/**
 * START DOCUMENT
 */

export interface Document extends BaseObject, SocialProps {
  fecha_alta: string;
  autores: string;
  fuente: {
    nombre: string;
    url?: string;
  };
  descripcion_resumen: string;
  youtube_video_id?: string;
  atach: number;
  tipo: string;
  reference: string;
  url: string;
  id: number;
  nombre: string;
  fecha: string;
  descripcion: string;
  fecha_relativa_corta: string;
  attachment: {
    content_type: string;
    content_length: string;
    url: string;
    downloads: number;
  };
  keywords: string[];
}

/**
 * START DOCUMENT SPONSORED
 */

export interface DocumentSponsored extends BaseObject, SocialProps {
  fecha_alta: string;
  autores: string;
  fuente_completa: {
    nombre: string;
    url?: string;
  };
  empresa_nombre: string;
  empresa: Business;
  fuente: string;
  ano_pubicacion: string;
  url_banner: string;
  link_banner: string;
  descripcion_resumen: string;
  youtube_video_id?: string;
  atach: number;
  tipo: string;
  reference: string;
  url: string;
  id: number;
  nombre: string;
  fecha: string;
  descripcion: string;
  fecha_relativa_corta: string;
  attachment: {
    content_type: string;
    content_length: string;
    url: string;
    downloads: number;
  };
  keywords: string[];
}

/**
 * START TICKET
 */

export interface TicketEvent {
  id: number;
  user: UserMin;
  text: string;
  type: number;
  date: string;
  attachments?: {
    url: string;
    contentType: string;
    filename: string;
  }[];
}

export interface Ticket extends BaseObject, SocialProps {
  respuesta: string;
  responsable_login: string;
  puedeCompartirse: number;
  empresa?: Business;
  usuario_login: string;
  url: string;
  id: number;
  imagenes: TicketImageAttachment[];
  cliente_object: User;
  usuario: string;
  fecha: string;
  responsable: string;
  visitas: number;
  ubicacion: Location;
  estado: string;
  link_responsable: string;
  categoria: string;
  responsable_object: User;
  link_cliente: string;
  tipo_responsable: string;
  reference: string;
  subcategoria: string;
  url_empresa?: string;
  nombre: string;
  localidad: string;
  archivos_adjuntos: number;
  fecha_cierre_relativa_corta: string;
  fecha_cierre: string;
  consulta_resumen: string;
  identificador_empresa?: string;
  fecha_relativa_corta: string;
  eventos?: TicketEvent[];
  tematica: {
    id: number;
    nombre: string;
  }[];
}

export interface TicketMin extends BaseObject, SocialProps {
  id: number;
  fecha_cierre: string;
  cliente_object: User;
  responsable_object: User;
  empresa?: Business;
  ubicacion: string;
  pregunta: string;
  respuesta: string;
  archivos_adjuntos: number;
  imagenes: TicketImageAttachment[];
  tematica: {
    id: number;
    nombre: string;
  }[];
}

interface TicketImageAttachment {
  src: string;
  size: { w: string; h: string };
}

/**
 * START ONLINE EVENT
 */

export interface OnlineEvent extends BaseObject {
  id: number;
  titulo: string;
  descripcion: string;
  fecha_inicio: string;
  estado: number;
  nombre_moderador: string;
  id_moderador: number;
  url: string;
  thumbnails: string;
  libre: number;
  privado: number;
  cant_comentarios: number;
  envivo: number;
  fecha: string;
  fecha_relativa_pretty: string;
  fecha_relativa_corta: string;
  reference: string;
  categoria_evento_online_id: number;
  cant_compartida: number;
  content: string;
  finalizado: number;
  nombre_categoria: string;
  fecha_finalizacion: string;
  img_cronograma?: string;
  variables: {
    autoinscripcion: string;
    pago: string;
    pago_dolares: string;
  };
  puedeAcceder: boolean;
  estaPago: boolean;
  suscripciones: number;
}

export interface OnlineEventMin extends BaseObject {
  id: number;
  titulo: string;
  fecha_inicio: string;
  id_moderador: number;
  url: string;
  fecha: string;
  categoria_evento_online_id: number;
  variables: {
    autoinscripcion: string;
    pago: string;
    pago_dolares: string;
  };
  inscriptos: number;
  inscriptos_por_estado: {
    no_facturado: number,
    facturado: number,
    pagado: number,
    cancelado: number,
    no_paga: number,
    a_cuenta: number,
  };
  dinero_a_cobrar: number,
}

/**
 * START TOOL
 */

export interface Tool extends BaseObject {
  id: number;
  nombre: string;
  descripcion_listado: string;
  imagen_header: string;
}

/**
 * START COMMON
 */

export interface UserMin {
  color_avatar: string;
  self: number;
  url_perfil: string;
  img_perfil?: string;
  login: string;
  verificado: number;
  nombre_completo: string;
  usuario_id: number;
  iniciales: string;
}

export interface CommentMin {
  mensaje: string;
  comentario_id: number;
  actividad: string;
  usuario: UserMin;
  respuestas_cant: number;
  fecha_relativa: string;
  fecha: string;
  adjuntos: {
    orden: number;
    filename: string;
    url: string;
    content_type?: string;
  }[];
  mensaje_plano: string;
  ref_objeto: string;
  comentario_padre_id?: number;
  url: string;
}

/**
 * START MOMENT
 */

export interface Moment {
  evento_online_id: number;
  titulo: string;
  descripcion: string;
  orador: string;
  external_url: string;
  fecha_cronograma: string;
  evento_online_momento_id: number;
  tiempo_ini_seg: number;
  tiempo_fin_seg: number;

  orador_objeto?: {
    img_perfil?: string;
  };
}

export interface Business {
  url_perfil: string;
  nombre_completo: string;
  le_interesa_a_usuario: number;
  id_mencion: string;
  url: string;
  activo: boolean;
  iniciales: string;
  nombre: string;
  actividad: string;
  color_avatar: string;
  empresa_id: number;
  stats: {
    cant_consultas: number;
    cant_seguidores: number;
    cant_seguidos: number;
    tiempoRespuesta: string;
    porcentajeRespuesta: number;
  };
  login: string;
  img_perfil?: string;
  interesa: number;
  descripcion?: string;
  verificado: number;
  state?: number;
  tematicas?: ThematicExpert[];

}

export interface Location {
  country: string;
  ref_objeto_pkg: string;
  lat: number;
  emoji_pais: string;
  ref_objeto_id: number;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  address: string;
  lng: number;
}

export interface User {
  self: number;
  empresa?: Business;
  url_perfil: string;
  email?: string;
  nombre_completo: string;
  id_mencion: string;
  activo: boolean;
  iniciales: string;
  nombre: string;
  color_avatar: string;
  actividad: string;
  localidad: string;
  localidad_id: number;
  stats: {
    cant_seguidores: number;
    cant_seguidos: number;
    cant_publicaciones: number;
    cant_ticketsRespondidos: number;
    cant_comentarios: number;
    cant_utils: number;
    responseTime: string;
    responsePercentage: number;
  };
  tusuario: {
    nombre: string;
  };
  ubicacion: Location;
  login: string;
  estado: string;
  img_perfil?: string;
  verificado: number;
  fecha_nacimiento?: Date;
  fecha_alta: string;
  usuario_id: number;
  apellido: string;
  edad?: string;
  ocupacion: string;
  ocupacion_id: number;
  atributo?: number;
  red?: {
    estado_a_usuario: number;
    estado_de_usuario: number;
  };
  descripcion?: string;
  genero: string;
  thematics?: ThematicExpert[];
  admin: boolean;
  state?: number;
  observaciones: string;
  countries?: string[];
  consulting_type?: string;
  acceso_pagos: {consultas: boolean, capacitaciones: boolean};
}

export interface ProfileActivity {
  actividad: string;
  url: string;
  fecha: string;
  fecha_relativa: string;
  objeto: {
    id: number;
    url: string;
    fecha: string;
    usuario_id: number;
    comentario_id: number;
    empresa_id: number;
    reference: string;
    nombre_completo: string;
    img_perfil: string;
    login: string;
    verificado: number;

    responsable_object: {
      nombre_completo: string;
      img_perfil: string;
      verificado: number;
      actividad: string;
      empresa: {
        img_perfil: string;
        actividad: string;
      };
    };
    cliente_object: {
      nombre_completo: string;
      img_perfil: string;
    };
    consulta_resumen: string;

    objeto_info: {
      actividad: string;
      titulo: string;
      usuario: {
        nombre_completo: string;
      };
      cliente: string;
      responsable: string;
      id: number;
    };

    usuario: {
      nombre_completo: string;
    };
    mensaje_plano: string;
  };
}

export interface ProfileConfigNotifications {
  ref_objeto_pkg: string;
  id: number;
  estado: boolean;
}

export interface Occupations {
  fecha_alta: string;
  ocupacion_id: number;
  nombre: string;
}

export interface Category {
  category: string;
  subcategory?: string;
  category_id: number;
  subcategory_id?: number;
}

export interface Thematic {
  nombre_completo: {
    id: number;
    nombre: string;
  }[];
  tematicas_hijas: {
    id: number;
    nombre: string;
  }[];
  id: number;
  nombre: string;
  subcategoria_id: number;
  keywords: [];

  hijas?: Thematic[];
}

export interface ThematicExpert {
  nombre_completo: {
    id: number;
    nombre: string;
  }[];
  hijas: ThematicExpert[];
  id: number;
  nombre: string;
  subcategoria_id?: number;
}

export interface Notifications {
  leida: number;
  notificacion_id: number;
  titulo: string;
  header: string;
  fecha_alta: string;
  image: {
    avatar: string;
  };
  url: string;
  ref_objeto_pkg: string;
  ref_objeto_id: number;
}

export interface MonthlyTickets {
  consultasMensualesExpertos: number;
}

export interface Publicity {
  width: number;
  actividad: string;
  pauta_orden: number;
  dummy: number;
  height: number;
  espacio_html: string;
}

export interface SearchHighlight {
  text_match: string;
  pkg: string;
  url: string;
  id: number;
  img: string;
}

export interface canConsult {
  success: number;
  puede: number;
}

export interface Coupons {
  success: boolean;
  cupon: string;
  cantidad: number;
  cupon_id: number,
}

export interface Coupon {
  cupon_id: number,
  estado: number,
  fecha_alta: string,
  ref_objeto_pkg?: string,
  ref_objeto_id?: number,
  evento_titulo?: string,
  fecha_baja?: string,
  empresa?: string
}

export interface Pack {
  nombre: string;
  id: number;
  precio: string;
  duracion: string;
  cantidad: number;
  precio_dolar: string;
}

export interface DetailPayment {
  evento_online_id?: number,
  titulo?: string,
  subs_total?: number,
  precio_total: number,
  subs_ids?: number[],
  ticket_id?: number,
  ticket_ids?: number[],
  precio_unitario_consulta?: number,
  cbu?: string,
}

export interface PaymentOrder {
  id: number,
  fecha_inicio: string,
  fecha_finalizacion: string,
  estado: number,
  monto_total: number,
  iva: number,
  subtotal: number,
  cbu: string,
  comentario?: string,
  items: DetailOrder[]
}

export interface DetailOrder {
  detalle: string,
  suma_monto: number,
  cantidad: number,
  ref_objeto_id: number,
  ref_objeto_pkg: string,
}

export type BackendObject =
  | Ticket
  | Newsletter
  | Document
  | Post
  | CommentMin
  | User
  | Business
  | Tool
  | OnlineEvent
  | Publicity;

export enum BackendObjectPKG {
  TICKET = 'Agroconsultas::Ticket',
  POST = 'Agroconsultas::Publicacion::Publicacion',
  DOCUMENT = 'Agroconsultas::Documento',
  DOCUMENT_SPONSORED = 'Agroconsultas::DocumentoSponsoreado',
  NEWSLETTER = 'Agroconsultas::Boletin',
  ONLINE_EVENT = 'Agroconsultas::EventoOnline',
  USER = 'Agroconsultas::Usuario',
  BUSINESS = 'Agroconsultas::Empresa',
  COMMENT = 'Agroconsultas::Comentario',
  EMAIL = 'Agroconsultas::Mail::TEnvio',
  PUSH = 'Agroconsultas::Notificacion::TNotificacion',
  BANNER = 'Banner',
}
