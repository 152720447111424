import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Box, Typography } from '@mui/material';
import { useUploadFilesStoreContext } from '@providers/hooks/fileuploads/UploadFilesStoreContext';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone';

import 'dropzone/dist/min/dropzone.min.css';
import styles from './FilesUploader.styles';

interface IPropsUploader {
  maxNumFiles?: number;
  maxFileSize?: number;
  title?: string;
  allowedFiles?: string;
  filesInComment?: {
    order: number;
    url: string;
    thumbnailUrl?: string;
    mediaType?: string;
    filename: string;
  }[];
  deleteFileInComment?: (orden: number) => void;
}

const MAX_FILES = 10;
const MAX_FILE_SIZE = 20971520; //Bytes

const MIME_TYPES_ACCEPTED = [
  'image/*',
  'application/pdf',
  //ppt
  'application/mspowerpoint,application/powerpoint,application/vnd.ms-powerpoint,application/x-mspowerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',

  //doc
  'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  //xls
  'application/vnd.ms-excel,application/excel,application/x-excel,application/x-msexcel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const FilesUploader: React.FC<IPropsUploader> = (props) => {
  const classes = styles();
  const uploadedFilesContext = useUploadFilesStoreContext();

  const removeFile = (file) => {

    if ( props.filesInComment?.length > 0 &&
        props.filesInComment.find(attach => attach.order.toString() == file.upload.uuid)) {
      props.deleteFileInComment(parseInt(file.upload.uuid));
    }

    uploadedFilesContext.remove(file.upload.uuid)
  }

  const onSuccessUpload = (file) => {
    const response = (file.xhr && file.xhr.response) ? JSON.parse(file.xhr.response) : {success : false};

    if (response.success) {
      const data = response.files[0];

      uploadedFilesContext.add(file.upload.uuid, data);

      if (file.previewElement) {
        return file.previewElement.classList.add('dz-success');
      }
    } else {
      onError(file, response.error || 'Error uploading file');
    }
  }

  const onError = (file, message) => {
    if (file.previewElement) {
      file.previewElement.classList.add('dz-error');

      if (typeof message !== 'string' && message.info) {
        message = message.info;
      }

      for (const node of file.previewElement.querySelectorAll(
        '[data-dz-errormessage]'
      )) {
        node.textContent = message;
      }
    }
  }

  const componentConfig = {
    postUrl: '/api/uploads/tmp',
  };

  //https://github.com/felixrieseberg/React-Dropzone-Component#callbacks
  const eventHandlers = {
    success: onSuccessUpload,
    removedfile: removeFile,
    error: onError,
    init: function(dropzone) {
      if (props.filesInComment?.length > 0) {
        props.filesInComment.forEach(element => {
          const mockFile = {
            upload: {
              uuid: element.order.toString(),
            },
            name: element.filename,
            size: 0 //agregar peso de adjunto,
          }

          dropzone.options.addedfile.call(dropzone, mockFile);
          dropzone.options.thumbnail.call(dropzone, mockFile, `${element.thumbnailUrl}` );
        })
      }
      else if (uploadedFilesContext.getAll().length > 0) {
        uploadedFilesContext.getAllWithKey().forEach(element => {
          const mockFile = {
            upload: {
              uuid: element[0],
            },
            name: element[1].filename,
            size: element[1].content_length,
          }

          dropzone.options.addedfile.call(dropzone, mockFile);
          dropzone.options.thumbnail.call(dropzone, mockFile, `${element[1].tmpfileurl}` );
        })
      }
    }
  };

  //https://github.com/dropzone/dropzone/blob/main/src/options.js
  const dropzoneConfig = {
    paramName : 'file',
    clickable : true,
    thumbnailWidth: 160,
    thumbnailHeight: 160,
    addRemoveLinks : true,
    dictRemoveFile : 'X',
    dictCancelUpload : 'X',
    dictInvalidFileType : 'No se permite subir este tipo de archivos',
    dictCancelUploadConfirmation : 'Cancelar la subida?',
    dictMaxFilesExceeded :  'Maxima cantidad de archivos superada',
    dictFileTooBig: 'El archivo es muy grande ({{filesize}}MB) y el máximo permitido es {{maxFilesize}}MB.',
    dictDefaultMessage : renderToStaticMarkup(
      <Box>
        <Box><FolderOutlinedIcon className={classes.folderIcon}/></Box>
        <Typography variant='body1' className={classes.typographyOne} display='inline'>Cargá el archivo</Typography>
        <Typography variant='body1' display='inline'>&nbsp;o arrastralo hasta acá</Typography>
      </Box>
    ),

    acceptedFiles: MIME_TYPES_ACCEPTED.join(','),
    maxFiles : props.maxNumFiles ? props.maxNumFiles : MAX_FILES,
    maxFilesize : props.maxFileSize ? props.maxFileSize : MAX_FILE_SIZE,
    /*
    previewTemplate: renderToStaticMarkup(
      <div className="dz-preview dz-file-preview">
        <div className="dz-details">
          <div className="dz-filename"><span data-dz-name="true"></span></div>
          <img data-dz-thumbnail="true" />
        </div>
        <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
        <div className="dz-success-mark"><span>✔</span></div>
        <div className="dz-error-mark"><span>✘</span></div>
        <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
      </div>

    )
     */
  };

  return (
    <Box className={classes.paper}>
      <Typography variant='body1' color='textPrimary' display='inline' className={classes.titleOne} >
        {props.title ? props.title : 'Adjuntá el archivo que quieras compartir'}
      </Typography>
      <Typography variant='body2' color='textSecondary' display='inline'>
        {props.allowedFiles ? '  (' + props.allowedFiles + ')' : '  (jpg, pdf, doc, docx, xls, xlsx, ppt, pptx)'}</Typography>
      <Box marginY="10px"/>

      <DropzoneComponent config={componentConfig} eventHandlers={eventHandlers} djsConfig={dropzoneConfig}/>
    </Box>
  );
};

export default FilesUploader;
